import request from '../../utils/request'

// 查询标签按照时间排序
export function allLabel () {
  return request({
    url: '/label/all',
    method: 'get'
  })
}

// 查询标签信息
export function getLabel (id) {
  return request({
    url: '/label/detail/' + id,
    method: 'get'
  })
}

// 根据标签查询文章
export function queryArticleByLabel (typeId) {
  return request({
    url: '/label/article/labelId/' + typeId,
    method: 'get'
  })
}
