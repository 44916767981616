<template>
  <div v-loading.fullscreen.lock="$store.state.pageLoading">
    <el-form ref="form" :model="user" label-width="auto" label-position="left" :rules="rules">
      <el-form-item label="原密码" prop="oldPassword" required="true">
        <el-input type="password" v-model="user.oldPassword"/>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword" required="true">
        <el-input type="password" v-model="user.newPassword"/>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword" required="true">
        <el-input type="password" v-model="user.confirmPassword"/>
      </el-form-item>
      <el-form-item label="邮箱" prop="email" required="true">
        <el-input v-model="user.email" readonly="readonly"/>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="$commonFun.isMobile() ? 16 : 8">
          <el-form-item label="验证码" prop="emailCode" required="true">
            <el-input v-model="user.emailCode"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="sendEmailCode()" :disabled="emailSendDisabled" :loading="emailSendLoading"
                     id="emailSend">{{emailSendText}}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
  <div class="footer">
    <el-button @click="cancel">取消</el-button>
    <el-button type="primary" @click="save('form')" :loading="saveLoading">保存</el-button>
  </div>
</template>

<script>
import { getUser, sendEmailCode, updatePassword } from '@/apis/comm/user'

export default {
  name: 'UserPassword',
  data () {
    return {
      user: {
        id: this.id,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        email: '',
        emailCode: ''
      },
      rules: {
        newPassword: [
          {
            required: true,
            validator: this.checkNewPass,
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            validator: this.checkConfirmPass,
            trigger: 'blur'
          }
        ]
      },
      saveLoading: false,
      emailSendLoading: false,
      emailSendDisabled: false,
      emailSendText: '发送验证码'
    }
  },
  props: ['id'],
  mounted () {
    this.getUserEmail()
  },
  methods: {
    getUserEmail () {
      getUser(this.id).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.user.email = res.data.email
        }
      })
    },
    cancel: function () {
      this.$emit('closePassword', false)
    },
    save: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          updatePassword(this.user).then((res) => {
            this.saveLoading = false
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message.success('修改成功')
              this.$emit('closePassword', true)
            }
          })
        } else {
          return false
        }
      })
    },
    checkConfirmPass (rule, value, callback) {
      if (!value) {
        callback(new Error('请确认新密码'))
      } else {
        if (value === this.user.newPassword) {
          callback()
        } else {
          return callback(new Error('两次输入的密码不一致'))
        }
      }
    },
    checkNewPass (rule, value, callback) {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else {
        if (value.length >= 8) {
          callback()
        } else {
          return callback(new Error('密码长度至少为8位'))
        }
      }
    },
    sendEmailCode () {
      if (!this.user.email) {
        this.$message.warning('请先输入邮箱')
      } else {
        const data = {
          recipientMailbox: [this.user.email]
        }
        this.emailSendLoading = true
        sendEmailCode(data).then((res) => {
          this.emailSendLoading = false
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('验证码发送成功，请注意查收')
            this.emailSendDisabled = true
            this.emailSendText = 60
            const _this = this
            const timer = setInterval(function () {
              _this.emailSendText--
              if (_this.emailSendText === 0) {
                _this.emailSendText = '发送验证码'
                _this.emailSendDisabled = false
                clearInterval(timer)
              }
            }, 1000)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 180px;
  height: 180px;
  display: block;
}

.footer {
  margin-bottom: 40px;
}
.footer button{
  float: right;
  margin-right: 12px;
}
</style>
