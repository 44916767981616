<template>
    <div class="footer-wrap">
      <div>
        ©2023 - {{new Date().getFullYear()}} By {{bloggerName}}
      </div>
      <a href="https://beian.miit.gov.cn/" target="_blank">
        {{ recordNo }}
      </a>
    </div>
</template>

<script>
export default {
  data () {
    return {
      bloggerName: '',
      recordNo: ''
    }
  },
  mounted () {
    this.getBloggerName()
    this.getRecordNo()
  },
  methods: {
    getBloggerName () {
      this.$commonFun.getSystemConfigValueByCode('bloggerName').then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.bloggerName = res.data
        }
      })
    },
    getRecordNo () {
      this.$commonFun.getSystemConfigValueByCode('recordNo').then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.recordNo = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.footer-wrap {
  width: 100%;
  line-height: 2;
  position: relative;
  padding: 40px 0px;
  color: #eee;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(-45deg, #ee7752, #ce3e75, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
}

.footer-wrap a {
  color: #eee !important;
}

@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
