import { createStore } from 'vuex'
import router from '../router'

export default createStore({
  state: {
    // 主管点击过的菜单集合
    clickMenus: [{
      title: '首页',
      path: '/manage',
      fullPath: '/manage',
      isCurrent: true
    }],
    // 页面加载
    pageLoading: false,
    // 博客打开侧边菜单
    openSideNav: false,
    // jwt密钥
    jwtSecret: 'ybyhy',
    // 登录弹出窗
    loginFlag: false,
    // 注册弹出窗
    registerFlag: false,
    // 忘及密码弹出窗
    forgetFlag: false,
    // 搜索弹出框
    searchFlag: false,
    // 文章搜索条件
    searchFilter: ''
  },
  getters: {
    getClickMenus: state => {
      return state.clickMenus
    },
    getJwtSecret: state => {
      return state.jwtSecret
    }
  },
  mutations: {
    addClickMenus (state, to) {
      const title = to.meta.title
      const fullPath = to.fullPath
      const path = to.path
      if (title) {
        let isAdd = true
        for (let i = 0; i < state.clickMenus.length; i++) {
          if (state.clickMenus[i].path === path || (state.clickMenus[i].path === '/manage' && path === '/manage/overview')) {
            isAdd = false
            state.clickMenus[i].isCurrent = true
          } else {
            state.clickMenus[i].isCurrent = false
          }
        }
        if (isAdd) {
          const clickMenu = {
            title: title,
            path: path,
            fullPath: fullPath,
            isCurrent: true
          }
          state.clickMenus.push(clickMenu)
        }
      }
    },
    clearClickMenus (state) {
      state.clickMenus = [{
        title: '首页',
        path: '/manage',
        fullPath: '/manage',
        isCurrent: true
      }]
    },
    removeMenu (state, path) {
      let change = false
      for (let i = 0; i < state.clickMenus.length; i++) {
        if (state.clickMenus[i].path === path) {
          // 移除需要删除的菜单，如果当前菜单时选中的，则默认最后一个选中
          if (state.clickMenus[i].isCurrent) {
            change = true
          }
          state.clickMenus.splice(i, 1)
          break
        }
      }
      if (change) {
        if (state.clickMenus.length > 0) {
          router.push(state.clickMenus[state.clickMenus.length - 1].path)
        } else {
          router.push('/manage/overview')
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
