import request from '../utils/request'

export default {
  // 时间转换为yyyy-MM-dd HH:mm:ss
  formatDateValue (now) {
    const year = this.dateZero(now.getFullYear()) // 取得4位数的年份
    const month = this.dateZero(now.getMonth() + 1) // 取得日期中的月份，其中0表示1月，11表示12月
    const date = this.dateZero(now.getDate()) // 返回日期月份中的天数（1到31）
    const hour = this.dateZero(now.getHours()) // 返回日期中的小时数（0到23）
    const minute = this.dateZero(now.getMinutes()) // 返回日期中的分钟数（0到59）
    const second = this.dateZero(now.getSeconds()) // 返回日期中的秒数（0到59）
    return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
  },
  // 日期前面加0
  dateZero (time) {
    if (time < 10) {
      time = '' + '0' + time
    }
    return time
  },
  // 查询系统配置
  getSystemConfigValueByCode (code) {
    return request({
      url: '/systemConfig/code/' + code,
      method: 'get'
    })
  },
  // 记录访问日志
  saveVisitLog () {
    return request({
      url: '/visitLog',
      method: 'post'
    })
  },
  // 查询文章数
  articleNum () {
    return request({
      url: '/common/article/num',
      method: 'get'
    })
  },
  // 标签统计
  labelStatistics () {
    return request({
      url: '/common/label/statistics',
      method: 'get'
    })
  },
  // 登录
  login (data) {
    return request({
      url: '/user/login',
      method: 'post',
      data
    })
  },
  // 退出登录
  logout () {
    return request({
      url: '/user/logout',
      method: 'get'
    })
  },
  // 是否为手机端
  isMobile () {
    const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    return flag
  }
}
