import request from '../utils/request'

// 上传文件
export function uploadFile (data) {
  return request({
    url: '/file',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 删除文件
export function removeFile (path) {
  return request({
    url: '/file/blog',
    method: 'delete',
    params: {
      path: path
    }
  })
}

// 主管删除文件
export function removeFileManage (path) {
  return request({
    url: '/file/manage',
    method: 'delete',
    params: {
      path: path
    }
  })
}
