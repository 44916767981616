<template>
  <el-drawer v-model="$store.state.openSideNav" :with-header="false" size="70%">
    <div v-if="user" class="user">
      <div class="avatar">
        <el-image :src="user.photo" fit="cover" />
      </div>
      <div class="name">
        {{user.name}}
      </div>
    </div>

    <hr v-if="user"/>

    <div class="user">
      <div class="overview">
        <div>
          <div>文章</div>
          <div class="number" @click="enter('/article')">{{articleNum}}</div>
        </div>
        <div>
          <div>分类</div>
          <div class="number" @click="enter('/types')">{{typeNum}}</div>
        </div>
        <div>
          <div>标签</div>
          <div class="number" @click="enter('/labels')">{{labelNum}}</div>
        </div>
      </div>
    </div>

    <hr/>

    <div class="nav">
      <el-menu
        background-color="#fff"
        class="el-menu-vertical-demo"
        text-color="#4c4948"
        unique-opened="true"
      >
        <el-menu-item index="1" @click="enter('/')">
          <el-icon><HomeFilled /></el-icon>
          <span>首页</span>
        </el-menu-item>
<!--        <el-menu-item index="2" @click="enter('/albums')">
          <el-icon><Picture /></el-icon>
          <span>相册</span>
        </el-menu-item>-->
        <el-menu-item index="3" @click="enter('/hot')">
          <el-icon><Sunny /></el-icon>
          <span>热搜</span>
        </el-menu-item>
        <el-menu-item index="4" @click="enter('/tools')">
          <el-icon><Tools /></el-icon>
          <span>工具导航</span>
        </el-menu-item>
        <el-menu-item index="5" @click="enter('/friendChains')">
          <el-icon><Link /></el-icon>
          <span>友链</span>
        </el-menu-item>
        <el-menu-item v-if="!user" index="6" @click="login()">
          <el-icon><User /></el-icon>
          <span>登录</span>
        </el-menu-item>
        <el-sub-menu v-if="user" index="7">
          <template #title>
            <el-icon><Setting /></el-icon>
            <span>设置</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="7-1" @click="userEdit()">
              <el-icon><Avatar /></el-icon>
              <span>个人设置</span>
            </el-menu-item>
            <el-menu-item index="7-2" @click="editPassword()">
              <el-icon><Lock /></el-icon>
              <span>修改密码</span>
            </el-menu-item>
            <el-menu-item index="7-3" @click="this.$refs.userModel.logout('true')">
              <el-icon><SwitchButton /></el-icon>
              <span>退出登录</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </el-menu>
    </div>
  </el-drawer>

  <user-model ref="userModel" :userId="user ? user.id : ''" />
</template>

<script>
import { getUser } from '@/apis/comm/user'
import { labelNum, typeNum } from '@/apis/blog/home'
import UserModel from '@/components/blog/UserModel'

export default {
  components: { UserModel },
  data: function () {
    return {
      user: {},
      articleNum: '',
      typeNum: '',
      labelNum: '',
      userEditPage: false,
      passwordPage: false
    }
  },
  mounted () {
    this.getUser()
    this.queryArticleNum()
    this.queryTypeNum()
    this.queryLabelNum()
  },
  computed: {
  },
  methods: {
    enter (path) {
      this.$store.state.openSideNav = false
      this.$router.push(path)
    },
    getUser () {
      getUser(this.id).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.user = res.data
        }
      })
    },
    queryArticleNum () {
      this.$commonFun.articleNum().then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.articleNum = res.data
        }
      })
    },
    queryTypeNum () {
      typeNum().then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.typeNum = res.data
        }
      })
    },
    queryLabelNum () {
      labelNum().then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.labelNum = res.data
        }
      })
    },
    login () {
      this.$store.state.openSideNav = false
      this.$refs.userModel.login()
    },
    userEdit () {
      this.$store.state.openSideNav = false
      this.$refs.userModel.userEdit()
    },
    editPassword () {
      this.$store.state.openSideNav = false
      this.$refs.userModel.editPassword()
    }
  }
}
</script>

<style scoped>
.el-drawer :deep(.el-drawer__body) {
  padding: 0px;
}

.user {
  text-align: center;
  margin-top: 40px;
}
.user .avatar {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.user .avatar .el-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 1s;
}
.user .avatar .el-image:hover {
  transform: rotateZ(360deg);
}

.user .name {
  font-size: 40px;
  line-height: 2;
}

.user .overview {
  display: flex;
  justify-content: space-around;
  font-size: 32px;
  line-height: 2;
}

.user .overview a {
  color: #303133;
}

hr {
  border: 4px dashed #d2ebfd;
  margin: 20px 0;
}

.nav {
  font-size: 36px;
  color: #4c4948;
  margin-left: 30px;
  margin-top: 60px;
}

/* 手机 */
@media (max-width: 759px) {
  .user .name {
    font-size: 30px;
  }
  .user .overview {
    font-size: 20px;
  }
  .nav {
    font-size: 24px;
  }
  .el-menu {
    border-right: none;
  }
}
</style>
