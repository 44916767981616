<template>
  <router-view/>
</template>

<script>
export default {
  watch: {
    // 监听openSideNav，如果为true，则父元素不能滚动
    '$store.state.openSideNav' (newVal, oldVal) {
      let cssStr = ''
      if (newVal) {
        cssStr = 'overflow-y: hidden'
      }
      document.getElementsByTagName('html')[0].style.cssText = cssStr
    },
    // searchFlag，如果为true，则父元素不能滚动
    '$store.state.searchFlag' (newVal, oldVal) {
      let cssStr = ''
      if (newVal) {
        cssStr = 'overflow-y: hidden'
      }
      document.getElementsByTagName('html')[0].style.cssText = cssStr
    }
  }
}
</script>

<style>
</style>
