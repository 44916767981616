<template>
  <div class="search-container " v-if="$store.state.searchFlag">
    <!-- 遮罩层 -->
    <div class=" mask" @click="close" @touchmove.stop.prevent></div>
    <!-- 内容 -->
    <div class="warpper ">
      <!-- 搜索框 -->
      <div class="search">
        <input class="searchInput" type="text" v-model="keywords" placeholder="请输入关键字...">
        <a class="commit" @click="search()">
          <el-icon size="20px"><Search /></el-icon>
        </a>
      </div>
      <!-- 标签搜索 -->
      <div class="tag-wrapper">
        <i class="iconfont icon-biaoqian"></i>
        <span>标签搜索</span>
        <div class="tag">
          <span @click="search(item.name)" :style="{ backgroundColor: `${randomColor()}` }" class="item"
                v-for="(item, index) in labelList" :key="index">
              {{ item.name }}
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { allLabel } from '@/apis/blog/label'

export default {
  data: function () {
    return {
      keywords: null,
      labelList: []
    }
  },
  mounted () {
    this.allLabel()
  },
  methods: {
    search (name) {
      this.$store.state.searchFilter = name || this.keywords
      if (this.$route.path !== '/home') {
        this.$router.push({
          path: '/home',
          query: {
            scrollDown: '1'
          }
        })
      }
      this.$store.state.searchFlag = false
    },
    randomColor () {
      const letters = '0123456789ABCDEF'
      let color = '#'
      do {
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)]
        }
      } while (color === '#FFFFFF' || color === '#000000')
      return color
    },
    close () {
      this.$store.state.searchFlag = false
    },
    allLabel () {
      allLabel().then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.labelList = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.search-container {
  height: 100%;
}

.search-container .mask {
  background: rgba(101, 101, 101, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.search-container .warpper {
  background-color: #fff;
  z-index: 99;
  position: fixed;
  top: 0;
  height: auto;
  width: 100%;
}
.search-container .warpper .search {
  width: 100%;
  margin-top: 30px;
  position: relative;
  top: 20px;
  margin: 0 auto;
}
.search-container .warpper .search .searchInput {
  outline: 0;
  border: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: rgb(235, 238, 245);
  width: 75%;
  height: 35px;
  padding-left: 10px;
  caret-color: #409eff;
  border: 1px solid rgb(235, 238, 245);
  color: #606266;
  margin-left: 20px;
}
.search-container .warpper .search .searchInput:focus {
  border: 1px solid #409eff;
  background-color: #fff;
}

.search-container .warpper .search .commit {
  position: absolute;
  top: 0px;
  height: 37px;
  width: 15%;
  background-color: #409eff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  text-decoration: none;
  text-align: center;
}
.search-container .warpper .search .commit .el-icon {
  margin-top: 8px;
  color: #fff;
}

.search-container .warpper .tag-wrapper {
  margin-top: 50px;
}
.search-container .warpper .tag-wrapper i {
  margin-left: 20px;
  margin-right: 10px;
}
.search-container .warpper .tag-wrapper i,span {
  font-size: 16px;
}
.search-container .warpper .tag-wrapper .tag {
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.search-container .warpper .tag-wrapper .tag span {
  text-decoration: none;
  margin-left: 10px;
  padding: 5px;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: all .3s;
}
.search-container .warpper .tag-wrapper .tag span:hover {
  border-radius: 0;
}
</style>
