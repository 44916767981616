<template>
  <div v-loading.fullscreen.lock="$store.state.pageLoading">
    <el-form ref="form" :model="user" label-width="auto" label-position="left">
      <el-form-item label="用户名称" prop="name" required="true">
        <el-input v-model="user.name"/>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile" required="true">
        <el-input v-model="user.mobile"/>
      </el-form-item>
      <el-form-item label="邮箱" prop="email" required="true">
        <el-input v-model="user.email"/>
      </el-form-item>
      <el-form-item label="头像" prop="photo" required="true">
        <el-upload class="avatar-uploader" :http-request="uploadFile" :before-remove="removeFile">
          <img v-if="user.photo" :src="user.photo" class="avatar" />
          <el-icon v-if="!user.photo" class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
  <div class="footer">
    <el-button @click="cancel">取消</el-button>
    <el-button type="primary" @click="save('form')" :loading="saveLoading">保存</el-button>
  </div>
</template>

<script>
import { updateUser, getUser } from '@/apis/comm/user'
import { removeFile, uploadFile } from '@/apis/file'

export default {
  name: 'UserEdit',
  data () {
    return {
      user: {
        id: '',
        name: '',
        mobile: '',
        email: '',
        photo: ''
      },
      saveLoading: false
    }
  },
  props: ['id'],
  mounted () {
    this.getUser()
  },
  methods: {
    getUser () {
      getUser(this.id).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.user = res.data
        }
      })
    },
    uploadFile (param) {
      const formData = new FormData()
      formData.append('multipartFile', param.file)
      uploadFile(formData).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.$message.success('上传成功')
          this.photo.url = res.data
        }
      })
    },
    removeFile (param, uploadFiles) {
      removeFile(this.photo.url).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
          return false
        } else {
          this.$message.success('删除成功')
          this.photo.url = ''
          return true
        }
      })
    },
    cancel: function () {
      this.$emit('closeUserEdit', false)
    },
    save: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          updateUser(this.user).then((res) => {
            this.saveLoading = false
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message.success('修改成功')
              this.$emit('closeUserEdit', true)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 180px;
  height: 180px;
  display: block;
}

.footer {
  margin-bottom: 40px;
}
.footer button{
  float: right;
  margin-right: 12px;
}
</style>

<style>
 .avatar-uploader .el-upload {
   border: 1px dashed var(--el-border-color);
   border-radius: 6px;
   cursor: pointer;
   position: relative;
   overflow: hidden;
   transition: var(--el-transition-duration-fast);
 }

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 180px;
  text-align: center;
}
</style>
