<template>
  <!--登录弹出框-->
  <el-dialog v-model="$store.state.loginFlag" v-if="$store.state.loginFlag" title="登录" center="true" :fullscreen="isMobile()">
    <login-model @closeLogin="closeLogin"></login-model>
  </el-dialog>
  <!--注册弹出框-->
  <el-dialog v-model="$store.state.registerFlag" v-if="$store.state.registerFlag" title="注册" center="true" :fullscreen="isMobile()">
    <register-model @closeLogin="closeLogin"></register-model>
  </el-dialog>
  <!--忘记密码弹出框-->
  <el-dialog v-model="$store.state.forgetFlag" v-if="$store.state.forgetFlag" title="忘记密码" center="true" :fullscreen="isMobile()">
    <forget-model @closeLogin="closeLogin"></forget-model>
  </el-dialog>

  <!--修改用户信息弹出框-->
  <el-dialog v-model="userEditPage" v-if="userEditPage" title="个人设置" center="true" :fullscreen="isMobile()">
    <user-edit @closeUserEdit="closeUserEdit" :id="userId"></user-edit>
  </el-dialog>
  <!--修改用户密码弹出框-->
  <el-dialog v-model="passwordPage" v-if="passwordPage" title="修改密码" center="true" :fullscreen="isMobile()">
    <user-password @closePassword="closePassword" :id="userId"></user-password>
  </el-dialog>
</template>

<script>
import UserPassword from '@/components/comm/user/Password'
import UserEdit from '@/components/comm/user/Edit'
import ForgetModel from '@/components/blog/ForgetModel'
import RegisterModel from '@/components/blog/RegisterModel'
import LoginModel from '@/components/blog/LoginModel'
import { checkLogin, getUser } from '@/apis/comm/user'

export default {
  components: { UserPassword, UserEdit, ForgetModel, RegisterModel, LoginModel },
  name: 'UserModel',
  data: function () {
    return {
      userEditPage: false,
      passwordPage: false
    }
  },
  props: ['userId'],
  methods: {
    isMobile () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    login () {
      this.$store.state.loginFlag = true
    },
    closeLogin (isReload) {
      this.$store.state.loginFlag = false
      this.$store.state.registerFlag = false
      this.$store.state.forgetFlag = false
      if (isReload) {
        location.reload()
      }
    },
    logout (isConfirm) {
      if (isConfirm) {
        this.$messageBox.confirm(
          '确认退出登录？', 'Warning',
          {
            confirmButtonText: '退出',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          this.$commonFun.logout().then((res) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              if (this.isMobile()) {
                this.$store.state.openSideNav = false
              }
              localStorage.removeItem('token')
              location.reload()
            }
          })
        }).catch(() => {
        })
      } else {
        this.$commonFun.logout().then((res) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            localStorage.removeItem('token')
            location.reload()
          }
        })
      }
    },
    userEdit () {
      checkLogin().then((res) => {
        if (res.code === 0) {
          this.userEditPage = true
        } else {
          this.$store.state.loginFlag = true
        }
      })
    },
    closeUserEdit (isUpdate) {
      this.userEditPage = false
      if (isUpdate) {
        getUser().then((res) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            const expireTime = this.user.expireTime
            this.user = res.data
            this.user.expireTime = expireTime
          }
        })
      }
    },
    closePassword (isUpdate) {
      this.passwordPage = false
      if (isUpdate) {
        this.logout(false)
      }
    },
    editPassword () {
      checkLogin().then((res) => {
        if (res.code === 0) {
          this.passwordPage = true
        } else {
          this.$store.state.loginFlag = true
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
