import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'
import store from '@/store'

const service = axios.create({
  baseURL: '/api',
  timeout: 60000
})

// 请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    if (localStorage.getItem('token')) {
      config.headers.token = JSON.parse(localStorage.getItem('token'))
    }

    /* if (!config.headers.notLoading) {
      // 页面加载
      store.state.pageLoading = true
    } */
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  function (response) {
    store.state.pageLoading = false

    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const code = dataAxios.code
    if (code === 100) {
      router.push('/manage/login')
      return Promise.reject(dataAxios.msg)
      // eslint-disable-next-line brace-style
    } /* else if (code !== 0) {
      ElMessage.error(dataAxios.msg)
      if (response.request.responseURL.indexOf('/api/file') > -1 && response.config.method === 'delete') {
        return dataAxios
      } else {
        return Promise.reject(dataAxios.msg)
      }
    } */ else {
      return dataAxios
    }
  },
  function (error) {
    store.state.pageLoading = false

    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)

export default service
