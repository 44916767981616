<template>
  <div class="header" :class="backClass" :style="transform">
    <div class="title">{{bloggerName}}</div>

    <!-- 电脑端 -->
    <div class="nav">
      <div class="menus-item search">
        <div class="search-input">
          <el-input placeholder="要搜点什么呢..." v-model="keywords"/>
        </div>
        <div class="search-op" @click="query()">
          <el-icon><Search /></el-icon>
        </div>
      </div>
      <div class="menus-item">
        <router-link class="menu-btn" to="/">
          <el-icon><HomeFilled /></el-icon> 首页
        </router-link>
      </div>
      <el-dropdown>
        <div class="menus-item">
          <a class="menu-btn">
            <el-icon><Compass /></el-icon> 发现 <el-icon><ArrowDownBold /></el-icon>
          </a>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <router-link class="menu-btn" to="/archive">
                <el-icon><FolderOpened /></el-icon>归档
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <router-link class="menu-btn" to="/types">
                <el-icon><DocumentCopy /></el-icon>分类
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <router-link class="menu-btn" to="/labels">
                <el-icon><CollectionTag /></el-icon>标签
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
<!--      <el-dropdown>
        <div class="menus-item">
          <a class="menu-btn">
            <el-icon><SwitchFilled /></el-icon> 娱乐 <el-icon><ArrowDownBold /></el-icon>
          </a>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <router-link class="menu-btn" to="/albums">
                <el-icon><Picture /></el-icon>相册
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>-->
      <div class="menus-item">
        <router-link class="menu-btn" to="/hot">
          <el-icon><Sunny /></el-icon> 热搜
        </router-link>
      </div>
      <div class="menus-item">
        <router-link class="menu-btn" to="/tools">
          <el-icon><Tools /></el-icon> 工具导航
        </router-link>
      </div>
      <div class="menus-item">
        <router-link class="menu-btn" to="/friendChains">
          <el-icon><Link /></el-icon> 友链
        </router-link>
      </div>
<!--      <div class="menus-item">
        <router-link class="menu-btn" to="/about">
          <el-icon><Promotion /></el-icon> 关于
        </router-link>
      </div>-->
<!--      <div class="menus-item">
        <router-link class="menu-btn" to="/leaveMessage">
          <el-icon><Message /></el-icon> 留言
        </router-link>
      </div>-->
      <div class="menus-item" v-if="!user || user.id === null || user.id === ''">
        <a class="menu-btn" @click="this.$refs.userModel.login()">
          <el-icon><User /></el-icon> 登录
        </a>
      </div>
      <el-dropdown v-if="user && user.id !== null && user.id !== ''" class="user">
        <div class="user-dropdown">
          <el-image :src="user.photo" fit="cover" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="this.$refs.userModel.userEdit()">
              <el-icon><Avatar /></el-icon>个人设置
            </el-dropdown-item>
            <el-dropdown-item @click="this.$refs.userModel.editPassword()">
              <el-icon><Lock /></el-icon>修改密码
            </el-dropdown-item>
            <el-dropdown-item divided @click="this.$refs.userModel.logout(true)">
              <el-icon><SwitchButton /></el-icon>退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!-- 手机端 -->
    <div class="nav-mobile">
      <div class="items" @click="openSerach"><el-icon><Search /></el-icon></div>
      <div class="items" @click="openSideNav"><el-icon><MoreFilled /></el-icon></div>
    </div>
  </div>

  <user-model ref="userModel" :userId="user ? user.id : ''" />
</template>

<script>
import { getUser } from '@/apis/comm/user'
import UserModel from '@/components/blog/UserModel'

export default {
  components: { UserModel },
  data: function () {
    return {
      backClass: '',
      scrollTop: '',
      transform: '',
      bloggerName: '',
      user: {},
      keywords: ''
    }
  },
  mounted () {
    this.scroll()
    window.addEventListener('scroll', this.scroll)
    this.getBloggerName()
    this.getUser()
  },
  methods: {
    isMobile () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    getUser () {
      getUser(this.id).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.user = res.data
        }
      })
    },
    scroll () {
      const scrollTop = window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (this.isMobile()) {
        this.backClass = 'back-block'
      } else {
        // 判断页面往下滚动的高度。如果高于60，则展示背景，否则隐藏背景
        if (scrollTop > 60) {
          this.backClass = 'back-block'
        } else {
          this.backClass = 'back-none'
        }
      }
      // 判断页面是在往下滚动还是往上滚动
      if (scrollTop > this.scrollTop) {
        // 往下
        this.transform = 'transform:translateY(-60px)'
      } else {
        // 往上
        this.transform = ''
      }
      this.scrollTop = scrollTop
    },
    // 打开侧边菜单
    openSideNav () {
      this.$store.state.openSideNav = true
    },
    getBloggerName () {
      this.$commonFun.getSystemConfigValueByCode('bloggerName').then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.bloggerName = res.data
        }
      })
    },
    openSerach () {
      this.$store.state.searchFlag = true
      this.transform = 'transform:translateY(-60px)'
    },
    query () {
      this.$store.state.searchFilter = this.keywords
      if (this.$route.path !== '/home') {
        this.$router.push({
          path: '/home',
          query: {
            scrollDown: '1'
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 60px;
  transition: all 0.3s;
}

.search {
  display: flex;
}
.search .search-input .el-input :deep(.el-input__wrapper) {
  border-radius: 24px 0px 0px 24px;
  box-shadow: none;
  border: 1px solid #dcdfe6;
  border-right: none;
  background: hsla(0,0%,100%,0);
}
.search .search-op {
  height: 32px;
  width: 22px;
  margin-top: 14px;
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
  border: 1px solid #dcdfe6;
  border-left: none;
  position: relative;
  right: 2px;
  background: hsla(0,0%,100%,0);
}
.search .search-op i {
  position: relative;
  bottom: 10px;
}

.back-block {
  background: hsla(0,0%,100%,.8);
  box-shadow: 0 5px 6px -5px hsl(0deg 0% 52% / 60%);
}
.back-block :deep(.el-input__inner) {
  color: #4c4948 !important;
}

.back-none {
  color: #eeeeee;
}
.back-none a,:deep(.el-input__inner) {
  color: #eeeeee !important;
}

.header .title {
  margin-left: 40px;
  font-weight: bold;
  font-size: 18px;
}

.header .nav {
  margin-right: 40px;
}
.header .nav a {
  color: #4c4948;
}
.header .nav .menus-item {
  margin-left: 20px;
  line-height: 60px;
  cursor: pointer;
}
.header .nav .menus-item .menu-btn {
  transition: all 0.3s;
}
.header .nav .menus-item .menu-btn:hover {
  color: #49b1f5;
}

.nav-mobile .items {
  margin-left: 20px;
}

.user {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  padding-top: 10px;
  border-radius: 8px;
}
.user .el-image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
}

/* 电脑 */
@media (min-width: 760px) {
  .nav-mobile {
    display: none;
  }
  .nav {
    display: flex;
  }
}

/* 手机 */
@media (max-width: 759px) {
  .header .title {
    margin-left: 20px;
  }

  .nav {
    display: none;
  }
  .nav-mobile {
    display: flex;
    margin-right: 20px;
  }
}

.el-scrollbar a {
  color: #4c4948;
}
</style>
