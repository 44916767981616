<template>
  <div id="app">
    <!-- 导航栏 -->
    <top-nav-bar></top-nav-bar>
    <!-- 侧边导航栏 -->
    <side-nav-bar></side-nav-bar>
    <!-- 内容 -->
    <router-view :key="$route.fullPath"/>
    <!-- 页脚 -->
    <Footer></Footer>
    <!-- 回到顶部 -->
    <el-backtop :right="30" :bottom="100" />
    <!-- 搜索模态框 -->
    <search-model></search-model>
  </div>
</template>

<script>
import TopNavBar from '@/components/blog/TopNavBar.vue'
import SideNavBar from '@/components/blog/SideNavBar.vue'
import Footer from '@/components/blog/Footer.vue'
import SearchModel from '@/components/blog/SearchModel.vue'

export default {
  name: 'index',
  components: { SearchModel, Footer, SideNavBar, TopNavBar },
  mounted () {
  }
}
</script>
