<template>
  <div v-loading.fullscreen.lock="$store.state.pageLoading">
    <el-form ref="form" :model="user" label-width="auto" label-position="left" :rules="rules">
      <el-form-item label="用户名称" prop="name" required="true">
        <el-input v-model="user.name"/>
      </el-form-item>
      <el-form-item label="账号" prop="username" required="true">
      <el-input v-model="user.username"/>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile" required="true">
        <el-input v-model="user.mobile"/>
      </el-form-item>
      <el-form-item label="邮箱" prop="email" required="true">
        <el-input v-model="user.email"/>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="$commonFun.isMobile() ? 16 : 8">
          <el-form-item label="验证码" prop="emailCode" required="true">
            <el-input v-model="user.emailCode"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="sendEmailCode()" :disabled="emailSendDisabled" :loading="emailSendLoading"
                     id="emailSend">{{emailSendText}}</el-button>
        </el-col>
      </el-row>
      <el-form-item label="头像" prop="photo" required="true">
        <el-upload class="avatar-uploader" :http-request="uploadFile" :before-remove="removeFile">
          <img v-if="user.photo" :src="user.photo" class="avatar" />
          <el-icon v-if="!user.photo" class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="密码" prop="password" required="true">
        <el-input v-model="user.password" type="password"/>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword" required="true">
        <el-input v-model="user.confirmPassword" type="password"/>
      </el-form-item>
    </el-form>
  </div>
  <div class="footer">
    <el-button @click="openLogin">去登录</el-button>
    <el-button type="primary" @click="register('form')">注册</el-button>
  </div>
</template>

<script>
import { removeFile, uploadFile } from '@/apis/file'
import { register, sendEmailCode } from '@/apis/comm/user'

export default {
  data: function () {
    return {
      user: {
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        mobile: '',
        email: '',
        photo: '',
        emailCode: ''
      },
      rules: {
        password: [
          {
            required: true,
            validator: this.checkPass,
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            validator: this.checkConfirmPass,
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            validator: this.checkMobile,
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            validator: this.checkEmail,
            trigger: 'blur'
          }
        ]
      },
      emailSendLoading: false,
      emailSendDisabled: false,
      emailSendText: '发送验证码'
    }
  },
  computed: {
  },
  methods: {
    openLogin () {
      this.$store.state.registerFlag = false
      this.$store.state.loginFlag = true
    },
    register (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          register(this.user).then((res) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message.success('注册成功')
              this.$commonFun.login(this.user).then((res) => {
                if (res.code !== 0) {
                  this.$message.error(res.msg)
                } else {
                  localStorage.setItem('token', JSON.stringify(res.data))
                  this.$emit('closeLogin', true)
                }
              })
            }
          })
        }
      })
    },
    checkEmail (rule, value, callback) {
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else {
        const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (!reg.test(value)) {
          return callback(new Error('邮箱格式不正确'))
        } else {
          callback()
        }
      }
    },
    checkMobile (rule, value, callback) {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else {
        const reg = /^(?:\+?86)?1[3-9]\d{9}$/
        if (!reg.test(value)) {
          return callback(new Error('手机号格式不正确'))
        } else {
          callback()
        }
      }
    },
    checkConfirmPass (rule, value, callback) {
      if (!value) {
        callback(new Error('请确认密码'))
      } else {
        if (value === this.user.password) {
          callback()
        } else {
          return callback(new Error('两次输入的密码不一致'))
        }
      }
    },
    uploadFile (param) {
      const formData = new FormData()
      formData.append('multipartFile', param.file)
      uploadFile(formData).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.$message.success('上传成功')
          this.user.photo = res.data
        }
      })
    },
    removeFile (param, uploadFiles) {
      removeFile(this.photo.url).then((res) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
          return false
        } else {
          this.$message.success('删除成功')
          this.photo.url = ''
          return true
        }
      })
    },
    sendEmailCode () {
      if (!this.user.email) {
        this.$message.warning('请先输入邮箱')
      } else {
        const data = {
          recipientMailbox: [this.user.email]
        }
        this.emailSendLoading = true
        sendEmailCode(data).then((res) => {
          this.emailSendLoading = false
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('验证码发送成功，请注意查收')
            this.emailSendDisabled = true
            this.emailSendText = 60
            const _this = this
            const timer = setInterval(function () {
              _this.emailSendText--
              if (_this.emailSendText === 0) {
                _this.emailSendText = '发送验证码'
                _this.emailSendDisabled = false
                clearInterval(timer)
              }
            }, 1000)
          }
        })
      }
    },
    checkPass (rule, value, callback) {
      if (!value) {
        callback(new Error('请输入密码'))
      } else {
        if (value.length >= 8) {
          callback()
        } else {
          return callback(new Error('密码长度至少为8位'))
        }
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 180px;
  height: 180px;
  display: block;
}

.footer {
  margin-bottom: 40px;
}
.footer button{
  float: right;
  margin-right: 12px;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
