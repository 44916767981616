import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element-plus相关组件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhLocale from 'element-plus/es/locale/lang/zh-cn'

// 粒子特效
import VueParticles from 'vue-particles'

// echarts
import echarts from './utils/echarts'

// 导入svg
import '@/assets/icons/index.js'
import SvgIcon from '@/components/SvgIcon'

// css
import './assets/css/reset.css'

// 公共方法
import commonFun from './apis/common'

// markdown
import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'

// markdown预览
import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import hljs from 'highlight.js'

VueMarkdownEditor.use(vuepressTheme, {
  Prism
})
VueMarkdownEditor.use(createEmojiPlugin())

VMdPreview.use(githubTheme, {
  Hljs: hljs
})

const app = createApp(App)
app.use(store).use(router)
app.use(ElementPlus, { locale: zhLocale })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(VueParticles)

app.use(VueMarkdownEditor)
app.use(VMdPreview)

// 关闭警告
app.config.warnHandler = () => null

app.config.globalProperties.$commonFun = commonFun
app.config.globalProperties.$echarts = echarts

app.component('svg-icon', SvgIcon)

app.mount('#app')
