<template>
  <div v-loading.fullscreen.lock="$store.state.pageLoading">
    <el-form ref="form" :model="user" label-width="auto" label-position="left" :rules="rules">
      <el-form-item label="邮箱" prop="email" required="true">
        <el-input v-model="user.email"/>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="$commonFun.isMobile() ? 16 : 8">
          <el-form-item label="验证码" prop="emailCode" required="true">
            <el-input v-model="user.emailCode"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="sendEmailCode()" :disabled="emailSendDisabled" :loading="emailSendLoading"
                     id="emailSend">{{emailSendText}}</el-button>
        </el-col>
      </el-row>
      <el-form-item label="新密码" prop="password" required="true">
        <el-input v-model="user.password" type="password"/>
      </el-form-item>
    </el-form>
  </div>
  <div class="footer">
    <el-button @click="openRegister">去注册</el-button>
    <el-button @click="openLogin">去登录</el-button>
    <el-button type="primary" @click="updatePass('form')" :loading="loading">修改密码</el-button>
  </div>
</template>

<script>
// import { emailLogin } from '../../api'
import { forgetUpdatePassword, sendEmailCode } from '@/apis/comm/user'

export default {
  data: function () {
    return {
      user: {
        password: '',
        email: '',
        emailCode: ''
      },
      loading: false,
      emailSendLoading: false,
      emailSendDisabled: false,
      emailSendText: '发送验证码',
      rules: {
        password: [
          {
            required: true,
            validator: this.checkPass,
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            validator: this.checkEmail,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
  },
  methods: {
    openRegister () {
      this.$store.state.forgetFlag = false
      this.$store.state.registerFlag = true
    },
    openLogin () {
      this.$store.state.forgetFlag = false
      this.$store.state.loginFlag = true
    },
    updatePass (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          forgetUpdatePassword(this.user).then((res) => {
            this.loading = false
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message.success('密码修改成功')
              this.$store.state.forgetFlag = false
              this.$store.state.loginFlag = true
            }
          })
        } else {
          return false
        }
      })
    },
    sendEmailCode () {
      if (!this.user.email) {
        this.$message.warning('请先输入邮箱')
      } else {
        const data = {
          recipientMailbox: [this.user.email]
        }
        this.emailSendLoading = true
        sendEmailCode(data).then((res) => {
          this.emailSendLoading = false
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('验证码发送成功，请注意查收')
            this.emailSendDisabled = true
            this.emailSendText = 60
            const _this = this
            const timer = setInterval(function () {
              _this.emailSendText--
              if (_this.emailSendText === 0) {
                _this.emailSendText = '发送验证码'
                _this.emailSendDisabled = false
                clearInterval(timer)
              }
            }, 1000)
          }
        })
      }
    },
    checkPass (rule, value, callback) {
      if (!value) {
        callback(new Error('请输入密码'))
      } else {
        if (value.length >= 8) {
          callback()
        } else {
          return callback(new Error('密码长度至少为8位'))
        }
      }
    },
    checkEmail (rule, value, callback) {
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else {
        const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (!reg.test(value)) {
          return callback(new Error('邮箱格式不正确'))
        } else {
          callback()
        }
      }
    }
  }
}
</script>

<style scoped>
.footer {
  margin-bottom: 40px;
}
.footer button{
  float: right;
  margin-right: 12px;
}
</style>
