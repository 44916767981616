import { createRouter, createWebHashHistory } from 'vue-router'
import IndexView from '../views/blog/Index.vue'
import store from '../store'
import commonFun from '../apis/common.js'
// import { ElMessage } from 'element-plus'
import { checkLogin } from '@/apis/comm/user'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        meta: { title: '首页', noCache: true, affix: true },
        component: () => import('../views/blog/Home.vue')
      },
      {
        path: 'archive',
        name: 'archive',
        meta: { title: '归档', noCache: true, affix: true },
        component: () => import('../views/blog/Archive.vue')
      },
      {
        path: 'types',
        name: 'types',
        meta: { title: '分类', noCache: true, affix: true },
        component: () => import('../views/blog/type/Index.vue')
      },
      {
        path: 'type/:id',
        name: 'typeInfo',
        meta: { title: '分类信息', noCache: true, affix: true },
        component: () => import('../views/blog/type/TypeArticle.vue')
      },
      {
        path: 'labels',
        name: 'labels',
        meta: { title: '标签', noCache: true, affix: true },
        component: () => import('../views/blog/label/Index.vue')
      },
      {
        path: 'label/:id',
        name: 'labelInfo',
        meta: { title: '标签信息', noCache: true, affix: true },
        component: () => import('../views/blog/label/LabelArticle.vue')
      },
      {
        path: 'albums',
        name: 'albums',
        meta: { title: '相册', noCache: true, affix: true },
        component: () => import('../views/blog/album/Index.vue')
      },
      {
        path: 'album/:photoAlbumId',
        name: 'album',
        meta: { title: '相册详情', noCache: true, affix: true },
        component: () => import('../views/blog/album/Detail.vue')
      },
      {
        path: 'friendChains',
        name: 'friendChains',
        meta: { title: '友情链接', noCache: true, affix: true },
        component: () => import('../views/blog/friendChain/Index.vue')
      },
      {
        path: 'about',
        name: 'about',
        meta: { title: '关于', noCache: true, affix: true },
        component: () => import('../views/blog/About.vue')
      },
      {
        path: 'article/:id',
        name: 'articleDetail',
        meta: { title: '文章详情', noCache: true, affix: true },
        component: () => import('../views/blog/ArticleDetail.vue')
      },
      {
        path: 'hot',
        name: 'hot',
        meta: { title: '热点', noCache: true, affix: true },
        component: () => import('../views/blog/Hot.vue')
      },
      {
        path: 'tools',
        name: 'tools',
        meta: { title: '工具导航', noCache: true, affix: true },
        component: () => import('../views/blog/tool/Index.vue')
      }
    ]
  },
  {
    path: '/manage',
    name: 'manageHome',
    component: () => import('../views/manage/Home.vue'),
    redirect: '/manage/overview',
    meta: { title: '主管', noCache: true, affix: true },
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: () => import('../views/manage/Overview.vue'),
        meta: { title: '首页', noCache: true, affix: true }
      },
      {
        path: '',
        name: '',
        meta: { title: '文章管理', noCache: true, affix: true },
        children: [
          {
            path: 'article',
            name: 'article',
            component: () => import('../views/manage/article/Index.vue'),
            meta: { title: '文章管理', noCache: true, affix: true }
          },
          {
            path: 'label',
            name: 'label',
            component: () => import('../views/manage/label/Index.vue'),
            meta: { title: '标签管理', noCache: true, affix: true }
          },
          {
            path: 'type',
            name: 'type',
            component: () => import('../views/manage/type/Index.vue'),
            meta: { title: '分类管理', noCache: true, affix: true }
          }
        ]
      },
      {
        path: '',
        name: '',
        meta: { title: '网站管理', noCache: true, affix: true },
        children: [
          {
            path: 'friendChain',
            name: 'friendChain',
            component: () => import('../views/manage/friendChain/Index.vue'),
            meta: { title: '友情链接', noCache: true, affix: true }
          }
        ]
      },
      {
        path: '',
        name: '',
        meta: { title: '消息管理', noCache: true, affix: true },
        children: [
          {
            path: 'comment',
            name: 'comment',
            component: () => import('../views/manage/comment/Index.vue'),
            meta: { title: '评论管理', noCache: true, affix: true }
          },
          {
            path: 'leaveMsg',
            name: 'leaveMsg',
            component: () => import('../views/manage/leaveMsg/Index.vue'),
            meta: { title: '留言管理', noCache: true, affix: true }
          },
          {
            path: 'feedback',
            name: 'feedback',
            component: () => import('../views/manage/feedback/Index.vue'),
            meta: { title: '反馈管理', noCache: true, affix: true }
          },
          {
            path: 'msgTemplate',
            name: 'msgTemplate',
            component: () => import('@/views/manage/msg/TemplateIndex.vue'),
            meta: { title: '消息模板管理', noCache: true, affix: true }
          },
          {
            path: 'msgLog',
            name: 'msgLog',
            component: () => import('../views/manage/msg/Log.vue'),
            meta: { title: '消息日志管理', noCache: true, affix: true }
          }
        ]
      },
      {
        path: 'photoAlbum',
        name: 'photoAlbum',
        component: () => import('../views/manage/photoAlbum/Index.vue'),
        meta: { title: '相册管理', noCache: true, affix: true }
      },
      {
        path: 'photo',
        name: 'photo',
        component: () => import('../views/manage/photo/Index.vue'),
        meta: { title: '照片管理', noCache: true, affix: true }
      },
      {
        path: '',
        name: '',
        meta: { title: '日志管理', noCache: true, affix: true },
        children: [
          {
            path: 'visitLog',
            name: 'visitLog',
            component: () => import('../views/manage/visitLog/Index.vue'),
            meta: { title: '访问日志管理', noCache: true, affix: true }
          },
          {
            path: 'operLog',
            name: 'operLog',
            component: () => import('../views/manage/operLog/Index.vue'),
            meta: { title: '操作日志管理', noCache: true, affix: true }
          }
        ]
      },
      {
        path: '',
        name: '',
        meta: { title: '工具管理', noCache: true, affix: true },
        children: [
          {
            path: 'tool',
            name: 'tool',
            component: () => import('../views/manage/tool/Index.vue'),
            meta: { title: '工具列表', noCache: true, affix: true }
          },
          {
            path: 'toolType',
            name: 'toolType',
            component: () => import('../views/manage/tool/Type.vue'),
            meta: { title: '工具分类列表', noCache: true, affix: true }
          }
        ]
      },
      {
        path: '',
        name: '',
        meta: { title: '系统管理', noCache: true, affix: true },
        children: [
          {
            path: 'user',
            name: 'user',
            component: () => import('../views/manage/user/Index.vue'),
            meta: { title: '用户管理', noCache: true, affix: true }
          },
          {
            path: 'role',
            name: 'role',
            component: () => import('../views/manage/role/Index.vue'),
            meta: { title: '角色管理', noCache: true, affix: true }
          },
          {
            path: 'api',
            name: 'api',
            component: () => import('../views/manage/api/Index.vue'),
            meta: { title: '操作权限管理', noCache: true, affix: true }
          },
          {
            path: 'systemConfig',
            name: 'systemConfig',
            component: () => import('../views/manage/systemConfig/Index.vue'),
            meta: { title: '系统配置管理', noCache: true, affix: true }
          },
          {
            path: 'systemNotice',
            name: 'systemNotice',
            component: () => import('../views/manage/systemNotice/Index.vue'),
            meta: { title: '系统公告管理', noCache: true, affix: true }
          },
          {
            path: 'scheduleTask',
            name: 'scheduleTask',
            component: () => import('../views/manage/scheduleTask/Index.vue'),
            meta: { title: '定时任务管理', noCache: true, affix: true }
          }
        ]
      }
    ]
  },
  {
    path: '/manage/login',
    name: 'manageLogin',
    component: () => import('../views/manage/Login.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  // 路由跳转校验也买按权限
  // 进入正常页面
  if (to.fullPath === '/' || to.fullPath === '/home') {
    // 访问的首页，记录访问日志
    commonFun.saveVisitLog().then((res) => {
    })
    next()
  } else if (to.fullPath === '/404' || to.fullPath === '/manage/login') {
    next()
  } else {
    if (to.fullPath.startsWith('/manage')) {
      // 校验登录是否过期
      if (!localStorage.getItem('token')) {
        // 没有token，直接跳转登录
        next({
          path: '/manage/login'
        })
      } else {
        // 有token，校验token是否过期
        await checkLogin().then((res) => {
          if (res.code === 0) {
            // 主管页面，记录全局变量
            store.commit('addClickMenus', to)
            next()
          } else {
            next({
              path: '/manage/login'
            })
          }
        })
      }
    } else {
      next()
    }
  }
})

export default router
