<template>
  <div v-loading.fullscreen.lock="$store.state.pageLoading">
    <el-form ref="loginForm" :model="user" label-width="auto" label-position="left">
      <el-form-item label="账号" prop="username" required="true">
        <el-input v-model="user.username" placeholder="请输入账号/手机号/邮箱"/>
      </el-form-item>
      <el-form-item label="密码" prop="password" required="true">
        <el-input v-model="user.password" type="password"/>
      </el-form-item>
    </el-form>
  </div>
  <div class="footer">
    <el-button @click="openForget">忘及密码</el-button>
    <el-button @click="openRegister">去注册</el-button>
    <el-button type="primary" @click="login('loginForm')" :loading="loginLoading">登录</el-button>
  </div>
</template>

<script>
// import { emailLogin } from '../../api'
export default {
  data: function () {
    return {
      user: {
        username: '',
        password: ''
      },
      loginLoading: false
    }
  },
  computed: {
  },
  methods: {
    openRegister () {
      this.$store.state.loginFlag = false
      this.$store.state.registerFlag = true
    },
    openForget () {
      this.$store.state.loginFlag = false
      this.$store.state.forgetFlag = true
    },
    login (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true
          this.$commonFun.login(this.user).then((res) => {
            this.loginLoading = false
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              localStorage.setItem('token', JSON.stringify(res.data))
              this.$emit('closeLogin', true)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.footer {
  margin-bottom: 40px;
}
.footer button{
  float: right;
  margin-right: 12px;
}
</style>
