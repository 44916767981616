// 保存或更新
import request from '@/utils/request'

// 更新用户信息
export function updateUser (data) {
  return request({
    url: '/user',
    method: 'put',
    data
  })
}

// 更新用户密码
export function updatePassword (data) {
  return request({
    url: '/user/password',
    method: 'put',
    data
  })
}

// 用户忘记密码更新密码
export function forgetUpdatePassword (data) {
  return request({
    url: '/user/forget/password',
    method: 'put',
    data
  })
}

export function getUser () {
  return request({
    url: '/user',
    method: 'get'
  })
}

export function checkLogin () {
  return request({
    url: '/user/login/check',
    method: 'get'
  })
}

// 注册
export function register (data) {
  return request({
    url: '/user/register',
    method: 'post',
    data
  })
}

// 发送邮箱验证码
export function sendEmailCode (data) {
  return request({
    url: '/user/email/send',
    method: 'post',
    data
  })
}
