import request from '../../utils/request'

// 查询分类数
export function typeNum () {
  return request({
    url: '/blog/type/num',
    method: 'get'
  })
}

// 查询标签数
export function labelNum () {
  return request({
    url: '/blog/label/num',
    method: 'get'
  })
}

// 查询文章列表
export function articleList (data) {
  return request({
    url: '/blog/article',
    method: 'get',
    headers: {
      notLoading: true
    },
    params: data
  })
}
